const substitutedVariableOrDefault = require("./../../shared/environment/index.js").substitutedVariableOrDefault;

angular.module('bf.config.environmental', []).constant('ENVIRONMENTAL', {
    VERSION: substitutedVariableOrDefault('#{Octopus.Release.Number}', '0.0.2'),
    AUTH0: {
        CONNECTION: substitutedVariableOrDefault('#{AUTH0_CONNECTION}', 'HowNow-Portal-Admin-Staging'),
        DOMAIN: substitutedVariableOrDefault('#{IdentityDomain}', 'localhost:44321'),
        CLIENTID: substitutedVariableOrDefault('#{IdentitySiteClientId}', 'cp-admin-web'),
        CALLBACKURL: substitutedVariableOrDefault('#{AUTH0_CALLBACK_URL}', 'https://localhost:8090')
    },
    FILEAPI: {
        BASEURL: substitutedVariableOrDefault('#{FileApiEndPoint}', 'http://localhost:61169'),
        CLIENTID: substitutedVariableOrDefault('#{FileApiClientId}', '0QguxSaUUg8F1IyRxR95f8sgbtU8ofJY')
    },
    MAINAPI: {
        CLIENTID: substitutedVariableOrDefault('#{PortalApiClientId}', 'l6rJgbVKvB2UhtXijzyCAm0pLBeyGHuG')
    },
    BASEURLS: {
        USERSITE: substitutedVariableOrDefault('#{UserSite-Url}', 'https://localhost:8080'),
        API: substitutedVariableOrDefault('#{APIBASEURL}', 'http://localhost:59611'),
        PREVIEW: substitutedVariableOrDefault('#{Preview_Url}', 'http://localhost:64614'),
        SIGN: substitutedVariableOrDefault('#{Signature-Url}', 'http://localhost:61915/'),
        BRANDING: substitutedVariableOrDefault('#{Branding-Url}', 'https://brandingservsecaustaging.blob.core.windows.net/brandings/')
    },
    SETTINGS: {
        LOGKEY: substitutedVariableOrDefault('#{JavascriptLogKey}', "BDD05ED0E9AD469DA51D471DDDE036B4")
    }
});